// Customizable Area Start
import * as React from "react";
// custom components
import {
    Box,
    Button,
    Divider,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    Typography,
} from "@material-ui/core";
import {Calender_Icon, TimeIcon} from "../../ContentManagement/src/assets"
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import {editPencil, list} from "./assets";
import {user} from "../../email-account-registration/src/assets";
import VisitorAddController, {Props} from "./VisitorAddController";
import './style.css'
import {Field, Form, Formik} from "formik";
import moment from "moment";
import AddIcon from '@material-ui/icons/Add';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import {withTranslation} from "react-i18next";
import PhoneInput from "react-phone-input-2";
import AlertError from "../../../components/src/AlertError.web";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {GreyBuildingName} from "../../RequestManagement/src/assets";
// @ts-ignore
import {visitorData} from "../__mock__/MockData";
import {ComplexIcon, CubeIcon} from "../../TaskAllocator/src/assets";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";
import {ErrorHandler} from "../../../components/src/UtilFunction.web";
import {MobileIcon} from "../../ReviewAndApproval/src/assets"
import { PropertyManagerStyleWeb } from "../../ReviewAndApproval/src/PropertyManagerStyle.web";
class Visitors extends VisitorAddController{
  constructor(props: Props) {
    super(props);
  }

  handleTitle = (t: any) => {
    if (this.state.visitorId) {
        return `${t("Edit Visitor Request")}`;
    } else {
        return `${t("Add Visitor Request")}`;
    }
  }

  handleError = (errors: any, touched: any) => {
    if (errors && touched) {
        return <small className="error">{errors}</small>
    }
  }

  render() {
      // @ts-ignore
      const {t,i18n,classes} = this.props
      // @ts-ignore
      const language= i18n.language;
      const is_mobile = !!navigator.userAgent.match(/iphone|android|blackberry/ig) || false;

      return (
        <>
            <Grid item xs={12} md={12} className="auth-cols addvisitors" dir={languageCondition(language,"rtl","ltr")}>
                <Grid container style={{ margin: '1rem', width: '90%' }} >
                  <Grid item xs={12} style={{ display:"flex", alignItems:"center", gap:"1rem",justifyContent:"space-between"}} >
                      <Box style={{ display:"flex", alignItems:"center", gap:"1rem",marginBottom:"05px"}}>
                          <ArrowBackIcon className="AddVisitorBackButton" onClick={() => window.history.back()} />
                          <p className="bold-text" style={{ fontSize: '1.2rem', fontWeight: 600 }} >
                              {this.handleTitle(t)}
                          </p>
                      </Box>
                  </Grid>
                </Grid>
                <Divider/>
                <Box style={{minHeight:"95%",display:'flex',flexDirection:"column",alignItems:'center',justifyContent:"space-between"}} >
                    <Grid container spacing={2} style={{width:"90%"}} className={languageCondition(language, "arabic-grid inputPlaceholderRegistration", "inputPlaceholderRegistration")}>
                        <Formik initialValues={this.initialValuesVisitor()}
                                validationSchema={this.visitorAddSchema()}
                                validateOnMount={true}
                                enableReinitialize
                                onSubmit={(values) => { this.createVisitorRequest(values) }}
                        >
                        {({   values,
                              errors,
                              touched,
                              handleBlur,
                              isValid, handleChange,
                              setFieldValue }) => (
                                <Form className="commonForm" translate="yes" style={{width:"100%",marginTop:"25px"}} >
                                    <Grid container spacing={1}>
                                        <Grid className="add-visitor" item xs={12}>
                                            <FormControl fullWidth>
                                                <div className="date-time formGroup classifiedFormGroup" style={{border:"0.1px solid rgb(209 209 209 / 100%)",borderRadius:"25px",width:"auto",marginBottom:"1px",height:"27px"}}>
                                                    <Select
                                                        displayEmpty
                                                        name="Complex Name"
                                                        labelId="ComplexName"
                                                        className="selectVisitorComplex"
                                                        style={{color:"gray",width:"100%",marginLeft:"0px"}}
                                                        MenuProps={{
                                                            getContentAnchorEl: null,
                                                            anchorOrigin: {
                                                              vertical: 'bottom',
                                                              horizontal: 'left',
                                                            },
                                                            transformOrigin: {
                                                              vertical: 'top',
                                                              horizontal: 'left',
                                                            },
                                                            PaperProps: {
                                                              style: {
                                                                maxHeight: 200, 
                                                                marginTop: 8,
                                                              },
                                                            },
                                                          }}
                                                        onChange={(e: any) => {
                                                            this.manageComplex(e.target.value,setFieldValue)
                                                        }}
                                                        startAdornment={
                                                            <img src={ComplexIcon} className="frm-icons" alt="Warning Icon" style={{marginLeft:"5px",marginRight:"15px"}}/>
                                                        }
                                                        value={values.ComplexId}
                                                    >
                                                        <MenuItem disabled value="" className={languageCondition(language, "select-arabic-menu SelectComplexFacility", "SelectComplexFacility selectComplex")}>
                                                            {t("Select Complex / Building")}
                                                        </MenuItem>
                                                        {this.state.complexList.map((complex: any) => (
                                                            <MenuItem key={complex.id} value={complex.id} className={languageCondition(language, "select-arabic-menu SelectComplexFacility", "SelectComplexFacility selectComplexList")}>
                                                                {complex.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </div>
                                                {this.handleError(errors.ComplexId, touched.ComplexId)}
                                            </FormControl>
                                        </Grid>
                                        {
                                            !values.isBuilding &&
                                            <Grid className="add-visitor selectBuilding" item xs={12}>
                                                <FormControl fullWidth className="selectBuilding">
                                                    <div className="date-time formGroup classifiedFormGroup selectBuilding" style={{border:"0.1px solid rgb(209 209 209 / 100%)",borderRadius:"25px",width:"auto",marginBottom:"5px",height:"27px"}}>
                                                        <Select
                                                            displayEmpty
                                                            name="buildingId" labelId="ComplexName"
                                                            className="selectVisitorBuilding" style={{color:"gray",width:"100%"}}
                                                            MenuProps={{
                                                                getContentAnchorEl: null,
                                                                anchorOrigin: {
                                                                  vertical: 'bottom',
                                                                  horizontal: 'left',
                                                                },
                                                                transformOrigin: {
                                                                  vertical: 'top',
                                                                  horizontal: 'left',
                                                                },
                                                                PaperProps: {
                                                                  style: {
                                                                    maxHeight: 200, 
                                                                    marginTop: 8,
                                                                  },
                                                                },
                                                              }}
                                                            onChange={(e: any) => {
                                                                setFieldValue("buildingId", e.target.value);
                                                                this.getUnitList(e.target.value)
                                                            }}
                                                            startAdornment={
                                                                <img src={GreyBuildingName} className="frm-icons" alt="Warning Icon" style={{marginLeft:"5px",marginRight:"15px"}}/>
                                                            }
                                                            value={values.buildingId}
                                                            
                                                        >
                                                            <MenuItem disabled value="" data-testId={"selectBuildingLabelTestID"} className={languageCondition(language, "select-arabic-menu SelectComplexFacility", "SelectComplexFacility")}>
                                                                {t("Select Building")}
                                                            </MenuItem>
                                                            {this.state.buildingList.map((complex: any) => (
                                                                <MenuItem key={complex.id} value={complex.id} className={languageCondition(language, "select-arabic-menu SelectComplexFacility", "SelectComplexFacility")}>
                                                                    {complex.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </div>
                                                    {this.handleError(errors.buildingId, touched.buildingId)}
                                                </FormControl>
                                            </Grid>
                                        }
                                        <Grid className="add-visitor selectBuilding" item xs={12}>
                                            <FormControl fullWidth className="selectBuilding">
                                                <div className="date-time formGroup classifiedFormGroup selectBuilding" style={{border:"0.1px solid rgb(209 209 209 / 100%)",borderRadius:"25px",width:"auto",marginBottom:"5px",height:"27px"}}>
                                                    <Select
                                                        displayEmpty
                                                        name="buildingId" labelId="ComplexName"
                                                        className="selectVisitorUnit" style={{color:"gray",width:"100%"}}
                                                        MenuProps={{
                                                            getContentAnchorEl: null,
                                                            anchorOrigin: {
                                                              vertical: 'bottom',
                                                              horizontal: 'left',
                                                            },
                                                            transformOrigin: {
                                                              vertical: 'top',
                                                              horizontal: 'left',
                                                            },
                                                            PaperProps: {
                                                              style: {
                                                                maxHeight: 200, 
                                                                marginTop: 8,
                                                              },
                                                            },
                                                          }}
                                                        onChange={(e: any) => {
                                                            setFieldValue("unitId", e.target.value);
                                                        }}
                                                        startAdornment={
                                                            <img src={CubeIcon} className="frm-icons" alt="Warning Icon" style={{marginLeft:"5px",marginRight:"15px"}}/>
                                                        }
                                                        value={values.unitId}
                                                    >
                                                        <MenuItem disabled value=""  className={languageCondition(language, "select-arabic-menu SelectComplexFacility", "SelectComplexFacility selectVisitorUnit")}>
                                                            {t("Select Unit")}
                                                        </MenuItem>
                                                        {this.state.unitList.map((unit: any) => (
                                                            <MenuItem key={unit.id} value={unit.id}  className={languageCondition(language, "select-arabic-menu SelectComplexFacility", "SelectComplexFacility VisitorUnit")}>
                                                                {unit.apartment_name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </div>
                                                {this.handleError(errors.unitId, touched.unitId)}
                                            </FormControl>
                                        </Grid>
                                        <Grid className='formGroup' item xs={12} style={{marginBottom:"10px"}}>
                                            <Box className="formInputGrp" >
                                                <Field
                                                    className={languageCondition(language, "formInput_ar", "formInput selectVisiorName")}
                                                    name="visitorName"
                                                    placeholder={t("Visitor Name")}
                                                    style={{border:"0.1px solid rgb(209 209 209 / 100%)",borderRadius:"25px",color:"rgba(0, 0, 0, 0.6)"}}
                                                />
                                                <span className={languageCondition(language, "frmRightIcons", "frmLeftIcons selectComplex")}>
                                                    <img src={user} />
                                                </span>
                                            </Box>
                                            {this.handleError(errors.visitorName, touched.visitorName)}
                                        </Grid>
                                        <Grid item xs={12} style={{marginTop:"-10px"}} className={classes.registerManager}>
                                            <FormControl fullWidth>
                                                <Box className="mobile-box" style={{border:"0.1px solid rgb(209 209 209 / 100%)",marginBottom:"25px"}}>
                                                    <Box style={languageCondition(language,{marginRight:"10px"},{})}>
                                                        <FormControl variant="outlined">
                                                            <PhoneInput
                                                                inputProps={{ name: "selectCode" }}
                                                                enableSearch={true}
                                                                value={this.state.selectCode}
                                                                onChange={this.handleChangeCCode}
                                                                country={"us"}
                                                            />
                                                        </FormControl>
                                                    </Box>
                                                    <Box className="divider" />
                                                    <Input
                                                    
                                                        value={values.phone}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="phone"
                                                        className="mobile-input"
                                                        placeholder={t("Visitor Phone")}
                                                        
                                                        startAdornment={
                                                            <InputAdornment position="start">
                                                                <img src={MobileIcon} alt="" />
                                                            </InputAdornment>
                                                        }
                                                        style={{
                                                            marginLeft: '-26px',
                                                           
                                                            
                                                          }}
                                                    />
                                                </Box>
                                                {ErrorHandler(t, touched.phone, errors.phone)}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} style={{marginTop:"-10px"}}>
                                            <FormControl fullWidth>
                                                {
                                                    this.state.visitorDetails?.image?.url ?
                                                        <Box style={{height:"170px",backgroundImage:`url(${this.state.visitorDetails?.image?.url})`,backgroundPosition:"center",backgroundSize:"contain",backgroundRepeat:"no-repeat",display:'flex',justifyContent:'flex-end'}}>
                                                            <IconButton onClick={() => this.upload.click()} style={{backgroundColor:"#2B6FED",height:"50px",width:"50px"}}><img src={editPencil}/></IconButton>
                                                        </Box> :
                                                        <div
                                                            className="image-box"
                                                            onClick={() => {
                                                                this.upload.click();
                                                            }}
                                                        >
                                                            <AddIcon style={{fontSize:"45px",color:"#9c9c9c"}}/>
                                                            <Typography variant="body1" color="textSecondary" style={{color:"#7a7878"}}>{t("Add Visitor ID copy")}</Typography>
                                                            <Typography variant="body1">({t("optional")})</Typography>
                                                        </div>
                                                }
                                                <input
                                                    id="myInput"
                                                    type="file"
                                                    ref={(ref: any) => (this.upload = ref)}
                                                    style={{ display: "none" }}
                                                    accept="image/png, image/gif, image/jpeg"
                                                    onChange={(e: any) => {
                                                        setFieldValue("photo", e.currentTarget.files[0]);
                                                    }}
                                                    onBlur={handleBlur}
                                                    name="photo"
                                                />
                                                {/*@ts-ignore*/}
                                                {values.photo && <span className="file-name">{values.photo.name}</span>}
                                                {this.handleError(errors.photo, touched.photo)}
                                                <span />
                                            </FormControl>
                                        </Grid>
                                        <Grid className="add-visitor" item xs={6}>
                                            <FormControl fullWidth>
                                                <div className="date-time formGroup classifiedFormGroup" style={{border:"0.1px solid rgb(209 209 209 / 100%)",borderRadius:"25px",width:"auto",marginBottom:"5px"}}>
                                                    <Input
                                                        value={values.date}
                                                        style={{color:"gray",minWidth:"150px"}}
                                                        onChange={(e: any) => {
                                                            setFieldValue("date", e.target.value);
                                                        }}
                                                        onBlur={handleBlur}
                                                        id={"VisitorTimeBox"}
                                                        name="date"
                                                        placeholder={t("Select Date")}
                                                        className={!values.date && is_mobile ? "date placeHolderForDate" : "date visitorDateInput"}
                                                        // @ts-ignore
                                                        inputProps={{
                                                            min:moment().format("YYYY-MM-DD")
                                                        }}
                                                        type={"date"}
                                                    />
                                                    <span className="frmLeftIcons" style={{left:"10px"}}>
                                                        <img src={Calender_Icon} className="frm-icons" alt="Warning Icon"/>
                                                    </span>
                                                </div>
                                                {this.handleError(errors.date, touched.date)}
                                            </FormControl>
                                        </Grid>
                                        <Grid className="add-visitor" item xs={6}>
                                            <FormControl fullWidth>
                                                <div className="date-time formGroup classifiedFormGroup" style={{border:"0.1px solid rgb(209 209 209 / 100%)",borderRadius:"25px",width:"auto",marginBottom:"5px"}}>
                                                    <Input
                                                        value={values.time}
                                                        style={{color:"gray"}}
                                                        onChange={(e: any) => {
                                                            setFieldValue("time", e.target.value);
                                                        }}
                                                        id={"VisitorTimeBox"}
                                                        onBlur={handleBlur}
                                                        className={!values.time && is_mobile ? "date placeHolderForTime" : ""}
                                                        placeholder={t("Select Time")}
                                                        name="time"
                                                        fullWidth
                                                        type="time"
                                                    />
                                                    <span className="frmLeftIcons" style={{left:"10px"}}>
                                                        <img src={TimeIcon} className="frm-icons" alt="Warning Icon"/>
                                                    </span>
                                                </div>
                                            </FormControl>
                                            {this.handleError(errors.time, touched.time)}
                                        </Grid>
                                        <Grid item xs={12} style={{marginTop:"10px",marginBottom:"4px",marginLeft:"5px"}}>
                                            <Typography>
                                                {t("Is visitor coming with car?")}
                                            </Typography>
                                            <FormControl component="fieldset" >
                                                <RadioGroup aria-label="gender" style={{flexDirection:"row"}} name="gender1" value={values.withCar} onChange={(e)=> setFieldValue("withCar", e.target.value)}>
                                                    <FormControlLabel value="true" control={<Radio icon={<RadioButtonUncheckedIcon style={{color:"#525252"}} />}
                                                                                                   checkedIcon={<RadioButtonCheckedIcon style={{color:"#FC8434"}} />} />} label={t("Yes")} />
                                                    <FormControlLabel value="false" control={<Radio icon={<RadioButtonUncheckedIcon style={{color:"#525252"}} />}
                                                                                                    checkedIcon={<RadioButtonCheckedIcon style={{color:"#FC8434"}} />} />} label={t("No")} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        <Grid className='formGroup' item xs={12} style={{marginTop:"-10px"}}>
                                            <Box className="formInputGrp" >
                                                <Field
                                                    className={languageCondition(language, "formInput_ar", "formInput selectVisiorName")}
                                                    disabled={values.withCar === "false"}
                                                    name="carPlateNo"
                                                    placeholder={t("Car Plate Number")}
                                                    style={{border:"0.1px solid rgb(209 209 209 / 100%)",borderRadius:"25px",color:"rgba(0, 0, 0, 0.6)"}}
                                                />
                                                <span className={languageCondition(language, "frmRightIcons", "frmLeftIcons selectVisiorName")}>
                                                    <img src={list} />
                                                </span>
                                            </Box>
                                            {this.handleError(errors.carPlateNo, touched.carPlateNo)}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CloseButton type="submit" variant="contained" fullWidth size="large">
                                                {t("Submit")}
                                            </CloseButton>
                                        </Grid>
                                </Grid>
                            </Form>
                        )}
                        </Formik>
                    </Grid>
                <AlertError  show={this.state.showError} handleClose={()=> this.setState({showError: false})} message={this.state.error} />
                <Button style={{display:"hidden"}} className="submitCreateVisitor" onClick={()=> this.createVisitor(visitorData,11) }></Button>
                <Button style={{display:"hidden"}} className="updateCreateVisitor" onClick={()=> this.updateVisitor(visitorData,11) }></Button>
                </Box>
            </Grid>
        </>
    );
  }
}
export default withTranslation()(withStyles(PropertyManagerStyleWeb)(withRouter(Visitors)))

const CloseButton = withStyles((theme) => ({
    root: {
        color: "white",
        backgroundColor: "#2b6fed",
        fontWeight:"bold",
        borderRadius:"100px",
        height:"55px",
        '&:hover': {
            backgroundColor: "#2b6fef",
        },
    },
}))(Button);

// Customizable Area End
