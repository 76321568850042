// Customizable Area Start
import {IBlock} from "../../../framework/src/IBlock";
import {Message} from "../../../framework/src/Message";
import MessageEnum, {getName} from "../../../framework/src/Messages/MessageEnum";
import {runEngine} from "../../../framework/src/RunEngine";
import * as Yup from "yup";
import moment from "moment";
import CommonApiCallForBlockComponent from "../../../components/src/ApiCallCommon.web";
// import {toast} from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history:any;
  match:any;
  location:any;
  t:any
}

interface S {
  anchorEl :any ;
  anchorEl_1 :any ;
  loading: boolean;
  sortBy:any;
  status:any;
  pollListing:any;
  selectCode:any;
  visitorId:any;
  visitorDetails:any;
  inputType1:any;
  inputType2:any;
  error:any;
  showError:boolean;
  complexList:any;
  buildingList:any;
  unitList:any;
  isBuilding:boolean;
}

interface SS {
  id: any;
}


export default class CoverImageController extends CommonApiCallForBlockComponent<
  Props,
  S,
  SS
> {

  apiEmailLoginCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  upload: any;
  createVisitorId:string = "";
  visitorDetailsId:string = "";
  updateVisitorId:string = "";
  getComplexListId:string = "";
  getBuildingListId:string = "";
  getUnitListId:string = "";
  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ]
    
    this.state = {
      anchorEl:null,
      anchorEl_1:null,
      loading:false,
      sortBy : "" ,
      status:"",
      pollListing:[],
      selectCode:"+966",
      visitorId:"",
      visitorDetails:{},
      inputType1:"text",
      inputType2:"text",
      error:"",
      showError:false,
      complexList:[],
      buildingList:[],
      unitList:[],
      isBuilding:false,
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount() {
    this.getVisitorDetails()
    this.getComplexList()
  }

  handleChangeCCode = (e: any) => {
    console.log(e)
    if (e) {
      // @ts-ignore
      this.setState({selectCode: `+${e}` })
    }
  }


  initialValuesVisitor = () => {
    return {
      ComplexId: this.state?.visitorDetails?.society_management_id || "",
      isBuilding: this.state?.isBuilding || false,
      buildingId: this.state?.visitorDetails?.building_management?.id || "",
      unitId: this.state?.visitorDetails?.apartment_management?.id || "",
      visitorName: this.state?.visitorDetails?.name,
      phone: this.state?.visitorDetails?.mobile_number?.mobile_number,
      photo: "",
      date: this.state?.visitorDetails?.schedule_date,
      time: this.state?.visitorDetails?.schedule_time,
      withCar: this.state?.visitorDetails?.comming_with_vehicle ? "true" : "false",
      carPlateNo: this.state?.visitorDetails?.vehicle_detail?.car_number || "",
    }
  }

  getVisitorDetails = async () => {
    const visitorId = this.props.match.params.id
    if(visitorId){
      this.setState({visitorId:visitorId})
      const societyID = localStorage.getItem("society_id")
      this.visitorDetailsId = await this.apiCall({
        contentType: "application/json",
        method: "GET",
        endPoint: `/society_managements/${societyID}/bx_block_visitor/visitors/${visitorId}`,
      });
    }
  }

  getComplexList = async () => {
    const userType = localStorage.getItem("userType")
    this.getComplexListId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_society_management/society_managements/user_complex_list?user_type=${userType}`,
    });
  }

  getBuildingList = async (id:any) => {
    this.getBuildingListId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_address/building_list?society_management_id=${id}`,
    });
  }

  getUnitList = async (id:any) => {
    this.getUnitListId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/facility_reservations/user_unit_list?building_management_id=${id}`,
    });
  }


  createVisitorResponse = (responseJson:any) => {
    if(responseJson.message === "Successfully created"){
      this.props.history.push("/VisitorAddSuccess")
    }
  }

  updateVisitorResponse = (responseJson:any) => {
    if(responseJson.message === "Successfully updated"){
      this.props.history.push("/VisitorUpdateSuccess")
    }
  }

  getBuildingListResponse = (responseJson:any) => {
    if(responseJson.hasOwnProperty("data")){
      this.setState({buildingList:responseJson.data.buildings})
    }
  }

  getUnitListResponse = (responseJson:any) => {
    if(responseJson.hasOwnProperty("data")){
      this.setState({unitList:responseJson.data.apartment_list})
    }
  }

  async receive(from: string, message: Message) {
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(this.createVisitorId === apiRequestCallId ){
        this.createVisitorResponse(responseJson)
      }
      if(this.visitorDetailsId === apiRequestCallId ){
        this.visitorDetailsResponse(responseJson)
      }
      if(this.updateVisitorId === apiRequestCallId){
        this.updateVisitorResponse(responseJson)
      }
      if(this.getComplexListId === apiRequestCallId){
        this.getComplexResponse(responseJson)
      }
      if(this.getBuildingListId === apiRequestCallId){
        this.getBuildingListResponse(responseJson)
      }
      if(this.getUnitListId === apiRequestCallId){
        this.getUnitListResponse(responseJson)
      }
    }
  }

  visitorDetailsResponse = (responseJson:any) => {
    if(responseJson.hasOwnProperty("visitor")){
      this.setState({
        visitorDetails:responseJson.visitor.data.attributes,
        selectCode:`+${responseJson.visitor.data.attributes.mobile_number.country_code}`
      })
      this.getComplexList()
      this.getBuildingList(responseJson.visitor.data.attributes.society_management_id)
      this.getUnitList(responseJson.visitor.data.attributes?.building_management?.id)
    }else{
      window.history.back()
    }
  }

  getComplexResponse = (responseJson:any) => {
    if(responseJson.hasOwnProperty("complex")){
      const checkId = this.props.match.params.id
      if(checkId){
        const filterData = responseJson.complex.filter((item:any) => item.id === this.state.visitorDetails.society_management_id)[0]
        if(filterData?.is_building){
          this.setState({isBuilding:true})
          this.getUnitList(filterData?.is_building_list[0]?.id)
        }
      }
      this.setState({complexList:responseJson.complex})
    }
  }

  manageComplex = (e:any,setFieldValue:any) => {
    setFieldValue("ComplexId", e);
    const filterData = this.state.complexList.filter((item:any) => item.id === e)[0]
    if(filterData?.is_building){
      setFieldValue("isBuilding", true);
      setFieldValue("buildingId", filterData?.is_building_list[0]?.id);
      this.getUnitList(filterData?.is_building_list[0]?.id)
    }else {
      setFieldValue("isBuilding", false);
      this.getBuildingList(e)
    }
  }

  visitorAddSchema() {
    const {t} = this.props
    const validations = Yup.object().shape({
      ComplexId: Yup.string().required(t("Please Select Building / Complex")),
      isBuilding: Yup.boolean(),
      buildingId: Yup.string().when("isBuilding",{
        is: false,
        then:Yup.string().required(t("Please Select Building "))
      }),
      unitId: Yup.string().required(t("Please Select Unit")),
      // @ts-ignore
      visitorName: Yup.string().required(t(`Visitor Name is required`)).trim().matches(/^[A-Za-z\s\u0600-\u06FF]*$/,t("Only characters are allowed in name")),
      // phone: Yup.number()
      //     .typeError(t("Only numbers are allowed.")),
      //     .required(t("Mobile number is required.")),
      //     .positive(t("Negative numbers are not allowed.")),
      //     .integer(t("Number can't contain a decimal.")),
      //     .required(t("Mobile number is required.")),
      //     .matches(/^\d{10}$/, t("Phone number must be exactly 10 digits.")),
      //     .matches(/^\d+$/, t("Only numbers are allowed.")),
      phone: Yup.number()
      .typeError(t("Only numbers are allowed."))
      .required(t("Mobile number is required."))
      .positive(t("Negative numbers are not allowed."))
      .integer(t("Number can't contain a decimal."))
      .test('len', t("Phone number must be between 7 and 12 digits."), val => {
        return val && val.toString().length >= 7 && val.toString().length <= 12;
    })
      .test('is-valid-number', t("Only numbers are allowed."), (value) => {
        return value ? !isNaN(Number(value)) : true; // Checks if it's a valid number
      })
      .test('no-negative', t("Negative numbers are not allowed."), (value) => {
        return value ? Number(value) > 0 : true; // Ensures it's positive
      })
      .test('no-decimals', t("Number can't contain a decimal."), (value) => {
        return value ? Number.isInteger(Number(value)) : true; // Ensures it's an integer
      }),
      photo: Yup.mixed(),
      date: Yup.string()
          .required(t("Required"))
          .matches(/\S/, t("Required")),
      time: Yup.string()
          .required(t("Required"))
          .matches(/\S/, t("Required"))
          .when("date", (date:any, schema:any) => {
            const newDate = date && moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
            return schema.test({
              test: (time: any) => {
                if (date && time) {
                  const test = moment(newDate + ` ${time}`, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD HH:mm");
                  return moment(test).isAfter(new Date());
                }
                return true;
              },
              message: t("You have entered a past time. Please make sure that you enter a time in the future."),
            });
          }),
      withCar:Yup.boolean().required(t("Please select Visitor will come with Vehicle or not")),
      carPlateNo:Yup.string().when("withCar",{
        is: true,
        then:Yup.string().required(t("Please enter Car plate Number"))
      })
    });
    return validations
  }

  createVisitorRequest(values:any){
    if(this.checkPhone(values.phone)){
      let formData = new FormData()
      
      formData.append('visitor[building_management_id]',values.buildingId)
      formData.append('visitor[apartment_management_id]',values.unitId)
      formData.append('visitor[name]',values.visitorName)
      formData.append('visitor[mobile_number]', this.state.selectCode+values.phone)
      formData.append('visitor[schedule_date]', values.date)
      formData.append('visitor[schedule_time]',values.time)
      if(values.photo !== ""){
        formData.append('visitor[image]',values.photo,values.photo.name)
      }
      formData.append('visitor[comming_with_vehicle]', values.withCar)
      formData.append('visitor[vehicle_detail][car_number]', values.carPlateNo)
      if(this.state.visitorId){
        this.updateVisitor(formData,values.ComplexId)
      }else{
        this.createVisitor(formData,values.ComplexId)
      }}
  }

  checkPhone=(value:any)=>{
    let pettrn = /^5\d+$/
    if(value.includes('+'))
    {
      this.setState({error:'Please enter valid mobile number',showError:true})
      return false
    }else if(this.state.selectCode == '+966' ||this.state.selectCode == '+971' ){
      if(!(pettrn.test(value)))
      {
        this.setState({error:'Please enter valid mobile number',showError:true})
        return false
      }
      else{
        if(value.length==9){
          return true
        }else{
          this.setState({error:'Please enter valid mobile number',showError:true})
          return false
        }
      }
    }else {
      if(value.length==10){
        return true
      }else{
        this.setState({error:'Please enter valid mobile number',showError:true})
        return false
      }
    }
  }

  createVisitor = async (data:any,societyID:any) => {
    this.createVisitorId = await this.apiCallForFileUpload({
      // contentType: "multipart/form-data",
      method: "POST",
      endPoint: `/society_managements/${societyID}/bx_block_visitor/visitors`,
      body:data
    });
  }

  updateVisitor = async (data:any,societyID:any) => {
    this.updateVisitorId = await this.apiCallForFileUpload({
      // contentType: "",
      method: "PUT",
      endPoint: `/society_managements/${societyID}/bx_block_visitor/visitors/${this.state.visitorId}`,
      body:data
    });
  }
}

// Customizable Area End
