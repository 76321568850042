import React from "react";
// Customizable Area Start
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Box,
    Breadcrumbs,
    Button,
    Paper,
    MenuItem,
    Select,
    Typography,
    FormControl,
    IconButton,
    Divider,
    Grid,
    TableContainer,
    Dialog,
    DialogContent,
    DialogActions,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { styled } from "@material-ui/styles";
import MoreIcon from "@material-ui/icons/MoreVert";
import MainScreen from "./Company Employee/MainScreen.web";
import { withTranslation } from "react-i18next";
import AlertError from "../../../components/src/AlertError.web";
import PaginationModule from "../../StoreCredits/src/PaginationModule.web";
import Loader from "../../../components/src/Loader.web";
import { Link as NavLink } from "react-router-dom";
import SuccessAlert from "../../../components/src/AlertSuccess.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import SearchInput from "../../../components/src/CommonComponents/SearchInput.web";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import DialogHeader from "../../../components/src/MuiDialog/DialogHeader.web";
import NormalTextField from "../../../components/src/CommonComponents/NormalTextField.web";
import NormalSelect from "../../../components/src/CommonComponents/NormalSelect.web";
import { ErrorHandler } from "../../../components/src/UtilFunction.web";
import NormalTextArea from "../../../components/src/CommonComponents/NormalTextArea.web";
import { Menu } from "@szhsin/react-menu";
interface CountryList {
    id: string;
    type: string;
    attributes:
    {
        name: string
    }
}
// Customizable Area End

import MyTeamController, { Props, } from "./MyTeamController.web";

class MyTeam extends MyTeamController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderCompaniesData = () => {
        const { t, i18n }: { t: any, i18n: any } = this.props;
        const language = i18n.language;
        const pageData=this.state.page as number
        return (
            <TableWrapper>
                <Box>
                    <Breadcrumbs aria-label="breadcrumb"  dir={languageCondition(language, "rtl", "ltr")}>
                        <Typography className="navigateLink" onClick={() => this.handleNavigation("DashboardCompanyEmployee")} data-testId={"myDashboardHeadingTestId"}>{t("My Dashboard")}</Typography>
                        <Typography className="navigateLink" onClick={() => this.handleNavigation("DashboardCompanyEmployee")} data-testId={"generalDashboardHeadingTestId"}>{t("General Dashboard")}</Typography>
                        <Typography className="tabActive" data-testId={"myTeamHeadingTestId"}>{t("Total Companies")}</Typography>
                    </Breadcrumbs>
                </Box>
                <Typography className="companiesText bold-text" dir={languageCondition(language, "rtl", "ltr")}>{t("Total Companies")}</Typography>
                <Box className="headerContainer" dir={languageCondition(language, "rtl", "ltr")}>
                    <FormControl variant="outlined">
                        <SelectData
                            IconComponent={ExpandMoreIcon}
                            labelId="complexLabel"
                            id="demo-simple-select-outlined"
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                            data-testId={"myTeamCountrySelectTestId"}
                            onChange={this.handleCountryChange}
                            displayEmpty
                            value={this.state.country}
                            dir={languageCondition(language, "rtl", "ltr")}
                        >
                            <MenuItem value="">{t("Select Country")}</MenuItem>
                            <MenuItem value="all">{t("All")}</MenuItem>
                            {this.state.countryList.map((country: CountryList, countryIndex: number) => {
                                return (<MenuItem value={country.attributes.name} key={countryIndex} data-testId={`countryListItemTestId${countryIndex}`}>{country.attributes.name}</MenuItem>)
                            })}
                        </SelectData>
                    </FormControl>
                    <FormControl variant="outlined">
                        <SelectData
                            id="demo-simple-select-outlined"
                            IconComponent={ExpandMoreIcon}
                            labelId="complexLabel"
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                            data-testId={"myTeamCitySelectTestId"}
                            onChange={this.handleCityChange}
                            value={this.state.city}
                            displayEmpty
                            dir={languageCondition(language, "rtl", "ltr")}
                        >
                            <MenuItem value="">{t("Select City")}</MenuItem>
                            <MenuItem value="all">{t("All")}</MenuItem>
                            {this.state.cityList.map((city: any, cityIndex: number) => {
                                return (<MenuItem value={city.name} key={cityIndex}>{city.name}</MenuItem>)
                            })}
                        </SelectData>
                    </FormControl>
                    <FormControl variant="outlined">
                        <SelectData
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                            IconComponent={ExpandMoreIcon}
                            onChange={this.handleStatusChange}
                            displayEmpty
                            dir={languageCondition(language, "rtl", "ltr")}
                            data-testId={"myTeamStatusSelectTestId"}
                            value={this.state.status}
                            labelId="complexLabel"
                            id="demo-simple-select-outlined"
                        >
                            <MenuItem value="">{t("Select Status")}</MenuItem>
                            <MenuItem value="all">{t("All")}</MenuItem>
                            <MenuItem value="pending_approval">{t("Pending Approval")}</MenuItem>
                            <MenuItem value="approved">{t("Approved")}</MenuItem>
                            <MenuItem value="rejected">{t("Rejected")}</MenuItem>
                            <MenuItem value="deactive">{t("Deactive")}</MenuItem>
                        </SelectData>
                    </FormControl>
                    <Button
                        className="searchButton"
                        dir={languageCondition(language, "rtl", "ltr")}
                        variant="contained"
                        data-testId={"cooSearchBtnTestId"}
                        onClick={()=>this.getCompanyList("1")}
                        startIcon={<SearchIcon />}
                        color="primary"
                    >
                        {t("Search")}
                    </Button>
                    <Box className="filter-filter">
                        <Button
                            className="handleSearchFilterForInvoices clear-filter"
                            data-testId="clearFiltersTestId"
                            onClick={this.handleClearFiltersClick}
                        >
                            {t("Clear Filters")}
                        </Button>
                    </Box>
                </Box>

                <Grid item sm={12} md={12} xs={12}>
                    <Grid className="table-box-card">
                        <Box className="table-top">
                            <Box className="table-main-heading">
                                <h4 className="bold-text">{t("List of managed real estate companies")}</h4>
                            </Box>
                            <Box className="filter-head-box" style={{ margin: "0" }}>
                                <SearchInput
                                    label="Search by company name"
                                    onChange={this.handleSearchInputChange}
                                    t={t}
                                    data-testId="searchFieldTestId"
                                    language={language}
                                />
                            </Box>
                        </Box>
                        <Divider />
                        <TableContainer component={Paper} className="table-container">
                            <Table className="table-box" dir={languageCondition(language, "rtl", "ltr")}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="bold-text"  align="left">{t("#")}</TableCell>
                                        <TableCell className="bold-text" align="left">{t("Company")}</TableCell>
                                        <TableCell className="bold-text" align="left">{t("Regd. No.")}</TableCell>
                                        <TableCell className="bold-text" align="left">{t("City")}</TableCell>
                                        <TableCell className="bold-text" align="left">{t("HQ Location")}</TableCell>
                                        <TableCell className="bold-text" align="left">{t("Status")}</TableCell>
                                        <TableCell className="bold-text" align="left">{t("Managed By")}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.companiesData.map((companyItem: any, nameListTypeIndex: number) => (
                                        <TableRow>
                                            <TableCell className="tableCellHeading" align="left">{(pageData - 1) * 10 + nameListTypeIndex + 1}</TableCell>
                                            <TableCell component="th" align="left" scope="row" data-testId={`tableCompanyNameTestId${nameListTypeIndex}`}>
                                                {companyItem.attributes.company_name}
                                            </TableCell>
                                            <TableCell align="left" component="p">{companyItem.id}</TableCell>
                                            <TableCell align="left" component="p">{companyItem.attributes.city}</TableCell>
                                            <TableCell align="left" component="p">{companyItem.attributes.country}</TableCell>
                                            <TableCell align="left" component="p">
                                                <Box className={companyItem.attributes.status === "approved"? "statusField": "errorMsg"}>{companyItem.attributes.status.split("_").join(" ")}</Box>
                                            </TableCell>
                                            <TableCell align="left" component="p">{companyItem.attributes.managed_by === "" ? "-" : companyItem.attributes.managed_by}</TableCell>
                                            <TableCell align={languageCondition(language, "right", "left")}>
                                                <Menu
                                                    menuButton={
                                                        <IconButton className="my-team-menu">
                                                            <MoreIcon />
                                                        </IconButton>
                                                    }
                                                >
                                                    <MenuItem><NavLink to={`/CompanyEmployee/realestate-company-details/${companyItem.id}`}>{t("View Details")}</NavLink></MenuItem>
                                                    <MenuItem className="reassign-menu" onClick={() => this.handleReassign(companyItem)}>{t("Reassign")}</MenuItem>
                                                </Menu>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <PaginationData dir={languageCondition(language, "rtl", "ltr")}>
                                <PaginationModule pagination={this.state.paginationData} handlePagination={this.handlePagination} page={this.state.page} data-testId={"paginationTestId"} />
                            </PaginationData>
                        </TableContainer>
                    </Grid>
                </Grid>
            </TableWrapper>
        );
    };

    renderRealEstateData = () => {
        const { t, i18n }: { t: any, i18n: any } = this.props;
        const language = i18n.language;
        return (
            <RealEstateWrapper  dir={languageCondition(language, "rtl", "ltr")}>
                <Box>
                    <Breadcrumbs>
                        <Typography className="navigateRoute" onClick={() => this.handleNavigation("DashboardCompanyEmployee")} data-testId={"myDashboardHeadingTestId"}>{t("My Dashboard")}</Typography>
                        <Typography className="navigateRoute" onClick={() => this.handleNavigation("DashboardCompanyEmployee")} data-testId={"generalDashboardHeadingTestId"}>{t("General Dashboard")}</Typography>
                        <Typography color="primary" data-testId={"salesManagerHeadingTestId"}>{t("Companies Assigned to Me")}</Typography>
                    </Breadcrumbs>
                </Box>
                <Typography className="realEstateHeading bold-text">
                    {t("Real Estate Companies I am Managing")}
                </Typography>
                <Box className="selectContainer"  dir={languageCondition(language, "rtl", "ltr")}>
                    <FormControl variant="outlined">
                        <Paper>
                            <SelectData
                             dir={languageCondition(language, "rtl", "ltr")}
                                IconComponent={ExpandMoreIcon}
                                labelId="complexLabel"
                                id="demo-simple-select-outlined"
                                data-testId={"myTeamCountrySelectTestId"}
                                MenuProps={{
                                    style: {
                                        marginTop: "50px",
                                    },
                                }}
                                displayEmpty
                                onChange={this.handleCountryChange}
                                value={this.state.country}
                            >
                                <MenuItem value="">{t("Select Country")}</MenuItem>
                                <MenuItem value="all">{t("All")}</MenuItem>
                                {this.state.countryList.map((country: CountryList, countryIndex: number) => {
                                    return (<MenuItem value={country.attributes.name} key={countryIndex} data-testId={`countryListItemTestId${countryIndex}`}>{country.attributes.name}</MenuItem>)
                                })}
                            </SelectData>
                        </Paper>
                    </FormControl>
                    <FormControl variant="outlined">
                        <Paper>
                            <SelectData
                             dir={languageCondition(language, "rtl", "ltr")}
                                labelId="complexLabel"
                                id="demo-simple-select-outlined"
                                IconComponent={ExpandMoreIcon}
                                MenuProps={{
                                    style: {
                                        marginTop: "50px",
                                    },
                                }}
                                data-testId={"myTeamCitySelectTestId"}
                                value={this.state.city}
                                onChange={this.handleCityChange}
                                disabled={this.state.country ? false : true}
                                displayEmpty
                            >
                                <MenuItem value="">{t("Select City")}</MenuItem>
                                <MenuItem value="all">{t("All")}</MenuItem>
                                {this.state.cityList.map((city: any, cityindex: number) => {
                                    return (<MenuItem value={city.name} key={cityindex}>{city.name}</MenuItem>)
                                })}
                            </SelectData>
                        </Paper>
                    </FormControl>
                    <FormControl variant="outlined">
                        <Paper>
                            <SelectData
                                MenuProps={{
                                    style: {
                                        marginTop: "50px",
                                    },
                                }}
                                IconComponent={ExpandMoreIcon}
                                displayEmpty
                                data-testId={"myTeamStatusSelectTestId"}
                                value={this.state.status}
                                onChange={this.handleStatusChange}
                                dir={languageCondition(language, "rtl", "ltr")}
                                id="demo-simple-select-outlined"
                            >
                                <MenuItem value="">{t("Select Status")}</MenuItem>
                                <MenuItem value="all">{t("All")}</MenuItem>
                                <MenuItem value={"Pending"}>{t("Pending")}</MenuItem>
                                <MenuItem value={"Closed"}>{t("Closed")}</MenuItem>
                                <MenuItem value={"Terminated"}>{t("Terminated")}</MenuItem>
                                <MenuItem value={"Draft"}>{t("Draft")}</MenuItem>
                                <MenuItem value={"Active"}>{t("Active")}</MenuItem>
                                <MenuItem value={"Start"}>{t("Start")}</MenuItem>
                            </SelectData>
                        </Paper>
                    </FormControl>
                    <Button
                        className="searchBtn"
                        variant="contained"
                        color="primary"
                        startIcon={<SearchIcon />}
                        onClick={() => this.getCompanyList("1")}
                        data-testId={"salesSearchBtnTestId"}
                        dir={languageCondition(language, "rtl", "ltr")}
                    >
                        {t("Search")}
                    </Button>
                </Box>

                <Grid item sm={12} md={12} xs={12}>
                    <Grid className="table-box-card">
                        <Box className="table-top">
                            <Box className="table-main-heading">
                                <h4 className="bold-text">{t("List of managed real estate companies")}</h4>
                            </Box>
                            <Box className="filter-head-box" style={{ margin: "0" }}>
                                <SearchInput
                                    language={language}
                                    t={t}
                                    label="Search by company name"
                                    data-testId="searchFieldTestId"
                                    onChange={this.handleSearchInputChange}
                                />
                            </Box>
                        </Box>
                        <Divider />
                        <TableContainer component={Paper} className="table-container">
                            <Table dir={languageCondition(language, "rtl", "ltr")} className="table-box">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="bold-text estate-head">{t("Company")}</TableCell>
                                        <TableCell className="bold-text estate-head">{t("Regd. No.")}</TableCell>
                                        <TableCell className="bold-text estate-head">{t("City")}</TableCell>
                                        <TableCell className="estate-head bold-text">{t("HQ Location")}</TableCell>
                                        <TableCell className="estate-head bold-text">{t("Status")}</TableCell>
                                        <TableCell className="estate-head bold-text">{t("Managed By")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.companiesData.map((CompanyItem: any, companyItemIndex: number) => (
                                        <TableRow>
                                            <TableCell component="th" scope="row" data-testId={`tableCompanyNameTestId${companyItemIndex}`}>
                                                {CompanyItem.attributes.company_name}
                                            </TableCell>
                                            <TableCell>{CompanyItem.id}</TableCell>
                                            <TableCell>{CompanyItem.attributes.city}</TableCell>
                                            <TableCell>{CompanyItem.attributes.country}</TableCell>
                                            <TableCell>
                                                <Box
                                                    className={
                                                        CompanyItem.type === "approved"
                                                            ? "successMsg"
                                                            : "errorMsg"
                                                    }
                                                >
                                                    {CompanyItem.attributes.status.split("_").join(" ")}
                                                </Box>
                                            </TableCell>
                                            <TableCell>{CompanyItem.attributes.managed_by === "" ? "-" : CompanyItem.attributes.managed_by}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <FilterData dir={languageCondition(language, "rtl", "ltr")}>
                                <PaginationModule pagination={this.state.paginationData} handlePagination={this.handlePagination} page={this.state.page} />
                            </FilterData>
                        </TableContainer>
                    </Grid>
                </Grid>
            </RealEstateWrapper>
        );
    };

    renderTeamManagingData = () => {
        const { t, i18n }: { t: any, i18n: any } = this.props;
        const language = i18n.language;
        const pageData=this.state.page as number
        return (
            <TeamManagingWrapper>
                <Box>
                    <Breadcrumbs dir={languageCondition(language, "rtl", "ltr")} aria-label="breadcrumb">
                        <Typography className="navigateData" onClick={() => this.handleNavigation("DashboardCompanyEmployee")} data-testId={"myDashboardHeadingTestId"}>{t("My Dashboard")}</Typography>
                        <Typography className="navigateData" onClick={() => this.handleNavigation("DashboardCompanyEmployee")} data-testId={"generalDashboardHeadingTestId"}>{t("General Dashboard")}</Typography>
                        <Typography color="primary" data-testId={"accountManagerHeadingTestId"}>
                            {t("Real estate companies my team is managing")}
                        </Typography>
                    </Breadcrumbs>
                </Box>
                <Typography className="companiesText bold-text" dir={languageCondition(language, "rtl", "ltr")}>
                    {t("Real estate companies my team is managing")}
                </Typography>
                <Box className="selectContainer"  dir={languageCondition(language, "rtl", "ltr")}>
                    <FormControl variant="outlined">
                        <SelectData
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                            IconComponent={ExpandMoreIcon}
                            value={this.state.country}
                            data-testId={"myTeamCountrySelectTestId"}
                            displayEmpty
                            onChange={this.handleCountryChange}
                            dir={languageCondition(language, "rtl", "ltr")}
                        >
                            <MenuItem value="">
                                {t("Select Country")}
                            </MenuItem>
                            <MenuItem value="all">{t("All")}</MenuItem>
                            {this.state.countryList.map((country: CountryList, countryIndex: number) => {
                                return (<MenuItem value={country.attributes.name} key={countryIndex} data-testId={`countryListItemTestId${countryIndex}`}>{country.attributes.name}</MenuItem>)
                            })}
                        </SelectData>
                    </FormControl>
                    <FormControl variant="outlined">
                        <SelectData
                            IconComponent={ExpandMoreIcon}
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                            displayEmpty
                            data-testId={"myTeamCitySelectTestId"}
                            value={this.state.city}
                            onChange={this.handleCityChange}
                            dir={languageCondition(language, "rtl", "ltr")}
                        >
                            <MenuItem value="">
                                {t("Select City")}
                            </MenuItem>
                            <MenuItem value="all">{t("All")}</MenuItem>
                            {this.state?.cityList?.map((city: any, cityIndex: number) => {
                                return (<MenuItem value={city.name} key={cityIndex}>{city.name}</MenuItem>)
                            })}
                        </SelectData>
                    </FormControl>
                    <FormControl variant="outlined">
                        <SelectData
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                            value={this.state.status}
                            displayEmpty
                            data-testId={"myTeamStatusSelectTestId"}
                            onChange={this.handleStatusChange}
                            IconComponent={ExpandMoreIcon}
                            dir={languageCondition(language, "rtl", "ltr")}
                        >
                            <MenuItem value="">{t("Select Status")}</MenuItem>
                            <MenuItem value="all">{t("All")}</MenuItem>
                            <MenuItem value={"Draft"}>{t("Draft")}</MenuItem>
                            <MenuItem value={"Pending"}>{t("Pending")}</MenuItem>
                            <MenuItem value={"Start"}>{t("Start")}</MenuItem>
                            <MenuItem value={"Active"}>{t("Active")}</MenuItem>
                            <MenuItem value={"Terminated"}>{t("Terminated")}</MenuItem>
                            <MenuItem value={"Closed"}>{t("Closed")}</MenuItem>
                        </SelectData>
                    </FormControl>
                    <Button
                        onClick={() => this.getCompanyList("1")}
                        variant="contained"
                        startIcon={<SearchIcon />}
                        dir={languageCondition(language, "rtl", "ltr")}
                        color="primary"
                        data-testId={"accountSearchBtnTestId"}
                        className="searchButton"
                    >
                        {t("Search")}
                    </Button>
                </Box>

                <Grid item sm={12} md={12} xs={12}>
                    <Grid className="table-box-card">
                        <Box className="table-top">
                            <Box className="table-main-heading">
                                <h4 className="bold-text">{t("List of managed real estate companies")}</h4>
                            </Box>
                            <Box className="filter-head-box" style={{ margin: "0" }}>
                                <SearchInput
                                    onChange={this.handleSearchInputChange}
                                    t={t}
                                    data-testId="searchFieldTestId"
                                    label="Search by company name"
                                    language={language}
                                />
                            </Box>
                        </Box>
                        <Divider />
                        <TableContainer component={Paper} className="table-container">
                            <Table className="table-box" dir={languageCondition(language, "rtl", "ltr")}>
                                <TableHead>
                                    <TableRow className="tableCellHeading">
                                        <TableCell component="p" className="bold-text">{t("#")}</TableCell>
                                        <TableCell component="p" className="bold-text">{t("Company")}</TableCell>
                                        <TableCell component="p" className="bold-text">{t("Regd. No.")}</TableCell>
                                        <TableCell component="p" className="bold-text">{t("City")}</TableCell>
                                        <TableCell component="p" className="bold-text">{t("HQ Location")}</TableCell>
                                        <TableCell component="p" className="bold-text">{t("Status")}</TableCell>
                                        <TableCell component="p" className="bold-text">{t("Managed By")}</TableCell>
                                        <TableCell component="p"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.companiesData.length === 0 ?<Typography>{t("No data found")}</Typography> :this.state.companiesData.map((companyItem: any, nameListTypeIndex: number) => (
                                        <TableRow>
                                            <TableCell className="tableCellHeading" align="left">{(pageData - 1) * 10 + nameListTypeIndex + 1}</TableCell>
                                            <TableCell component="th" scope="row" data-testId={`tableCompanyNameTestId${nameListTypeIndex}`}>
                                                {companyItem.attributes.company_name}
                                            </TableCell>
                                            <TableCell component="p">{companyItem.id}</TableCell>
                                            <TableCell component="p">{companyItem.attributes.city}</TableCell>
                                            <TableCell component="p">{companyItem.attributes.country}</TableCell>
                                            <TableCell component="p">
                                                <Box
                                                    className={
                                                        companyItem.type === "approved"
                                                            ? "approvedMsg"
                                                            : "pendingMsg"
                                                    }
                                                >
                                                    {companyItem.attributes.status.split("_").join(" ")}
                                                </Box>
                                            </TableCell>
                                            <TableCell component="p">{companyItem.attributes.managed_by === "" ? "-" : companyItem.attributes.managed_by}</TableCell>
                                            <TableCell align={languageCondition(language, "right", "left")}>
                                                <Menu
                                                    menuButton={
                                                    <IconButton>
                                                        <MoreIcon />
                                                    </IconButton>
                                                    }
                                                >
                                                    <MenuItem><NavLink to={`/CompanyEmployee/realestate-company-details/${companyItem.id}`}>{t("View Details")}</NavLink></MenuItem>
                                                    <MenuItem onClick={() => this.handleReassign(companyItem)}>{t("Reassign")}</MenuItem>
                                                </Menu>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <Box className="paginationData" dir={languageCondition(language, "rtl", "ltr")}>
                                <PaginationModule pagination={this.state.paginationData} handlePagination={this.handlePagination} page={this.state.page} />
                            </Box>
                        </TableContainer>
                    </Grid>
                </Grid>
            </TeamManagingWrapper>
        );
    };

    renderReassignModal = () => {
        const { t, i18n }: { t: any, i18n: any } = this.props;
        const language = i18n.language;

        return <Dialog 
            dir={languageCondition(language, "rtl", "ltr")}
            data-testId="reassignModal"
            maxWidth="md"
            scroll="paper"
            fullWidth
            open={this.state.openReassignModal}
        >
            <Formik
                validationSchema={Yup.object().shape({
                    newAccountManager: Yup.string().required("Account manager is required"),
                    note: Yup.string().required("Note is required"),
                })}
                enableReinitialize={true}
                onSubmit={(values, { resetForm }) => {
                    this.setState({ loader: true, openReassignModal: false }, () => {
                        this.reassignToAnotherAccountManager(values);
                        resetForm();
                    });
                }}
                initialValues={this.state.reassignForm}
            >
                {({ values, getFieldProps, handleSubmit, errors, touched, setFieldValue }) => {
                    return (
                        <Form translate="yes" onSubmit={handleSubmit}>
                            <DialogHeader t={t} title="Assign to another Manager" onClose={this.handleReassignModal} />
                            <DialogContent dividers>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <NormalTextField
                                            language={language}
                                            isLabel={true}
                                            value={values.buildingId}
                                            t={t}
                                            label={t("Company ID")}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <NormalTextField
                                            language={language}
                                            disabled
                                            isLabel={true}
                                            value={values.buildingName}
                                            label={t("Company Name")}
                                            t={t}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <NormalTextField
                                            t={t}
                                            label={t("Sales Manager")}
                                            isLabel={true}
                                            language={language}
                                            value={values.salesManager}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <NormalTextField
                                            language={language}
                                            label={t("Current Account Manager")}
                                            isLabel={true}
                                            value={values.accountManager}
                                            t={t}
                                            disabled
                                        />
                                    </Grid>
                                    {this.state.userRole === "coo" && (
                                        <Grid item xs={12}>
                                            <NormalTextField
                                                language={language}
                                                label={t("New Sales Manager")}
                                                isLabel={true}
                                                value={values.newSalesManager}
                                                t={t}
                                                disabled
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <NormalSelect
                                            {...getFieldProps("newAccountManager")}
                                            t={t}
                                            language={language}
                                            label={t("New Account Manager")}
                                            option={this.state.accountManagerList.map((item: any) => ({
                                                label: item.attributes.employee_name,
                                                value: item.attributes.employee_account_id,
                                            }))}
                                            onChange={(event: any) => {
                                                const value = event.target.value;
                                                setFieldValue("newAccountManager", value);

                                                const reportPerson = this.state.accountManagerList.find(
                                                    (item: any) => Number(item.attributes.employee_account_id) === Number(event.target.value)
                                                );

                                                setFieldValue("newSalesManager", reportPerson?.attributes?.reporting_person?.full_name);
                                            }}
                                            id="reassignAccountManagerChangeTestId"
                                        />
                                        {ErrorHandler(t, touched.newAccountManager, errors.newAccountManager)}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <NormalTextArea
                                            {...getFieldProps("note")}
                                            t={t}
                                            language={language}
                                            label="Notes"
                                            maxRows={5}
                                            minRows={5}
                                            data-testId={"noteInputTestId"}
                                        />
                                        {ErrorHandler(t, touched.note, errors.note)}
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions className="dialog__button_group">
                                <Button
                                    className="cancel-button"
                                    data-testId="reassignCanceLButton"
                                    onClick={this.handleReassignModal}
                                >
                                    {t("Cancel")}
                                </Button>
                                <Button type="submit" className="add-button">
                                    {t("Reassign")}
                                </Button>
                            </DialogActions>
                        </Form>
                    );
                }}
            </Formik>
        </Dialog>
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <MainScreen>
                {this.state.userRole === "coo" && this.renderCompaniesData()}
                {this.state.userRole === "account_manager" && this.renderRealEstateData()}
                {this.state.userRole === "sales_manager" && this.renderTeamManagingData()}

                {this.renderReassignModal()}

                <AlertError show={this.state.showError} handleClose={this.handleAlertError} message={this.state.showErrorMessage} data-testId={"errorAlertTestId"} />

                <Loader loading={this.state.loader} />

                <SuccessAlert show={this.state.showSuccess} message={this.state.showSuccessMessage} handleClose={this.handleSuccessAlert} data-testId={"successAlertTestId"}/>
            </MainScreen>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withTranslation()(MyTeam);
export { MyTeam };
const SelectData = styled(Select)({
    background: "white",
    border:"1px solid #EAEAEA",
    borderRadius:"10px",
    "& .MuiSelect-outlined.MuiSelect-outlined": {
        paddingRight: "110px",
    },
});

const TableWrapper = styled(Box)({
    backgroundColor: "#F4F7FF",
    padding: "15px 25px",
    "& .searchButton": {
        borderRadius: "10px",
        textTransform: "capitalize",
        fontWeight: 700,
        fontSize: "20px",
        height: "55px",
        backgroundColor: "#2B6FED"
    },
    "& .listHeader": {
        padding: "15px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "15px",
        marginBottom: "5px",
    },
    "& .navigateLink": {
        fontWeight: 400,
        fontSize: "16px",
        color: "black",
        cursor: "pointer"
    },
    "& .headerContainer": {
        display: "flex",
        gap: "20px",
        flexWrap: "wrap"
    },
    "& .tabActive": {
        color: "#2b6fed",
        fontSize: "16px",
        fontWeight: 400,
    },
    "& .listContainer": {
        borderRadius: "10px"
    },
    "& .textOrange": {
        color: "orange",
        fontWeight: 700
    },
    "& .searchIcon": {
        color: "#808080"
    },
    "& .boldText": {
        fontWeight: 700
    },
    "& .companiesText": {
        fontWeight: 700,
        lineHeight: "27px",
        fontSize: "28px",
        marginBottom: "15px",
        marginTop: "15px",
    },
    "& .listPara": {
        lineHeight: "27px",
        fontSize: "22px",
        fontWeight: 700,
    },
    "& .tableCellHeading": {
        color: "black",
        fontSize: "18px",
        fontWeight: 700,
    },
    "& .statusField": {
        color: "#40CE74",
        backgroundColor: "#E4F8EB",
        fontWeight: 700,
        borderRadius: "20px",
        textTransform: "capitalize",
        padding: "5px 15px",
        width: "fit-content",
        maxWidth: "min-content",
    },
    "& .errorMsg": {
        color: "#FC924B",
        backgroundColor: "#FEF0E6",
        fontWeight: 700,
        borderRadius: "20px",
        textTransform: "capitalize",
        padding: "5px 15px",
        width: "fit-content",
        maxWidth: "min-content",
    },
});

const PaginationData = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    margin: "20px",
    "& .MuiPaginationItem-page.Mui-selected": {
        background: "#fc8e45",
        color: "#fff",
    },
});

const RealEstateWrapper = styled(Box)({
    backgroundColor: "#F4F7FF",
    padding: "15px 25px",
    "& .searchBtn": {
        borderRadius: "10px",
        textTransform: "capitalize",
        fontWeight: 700,
        fontSize: "20px",
    },
    "& .listHeading": {
        padding: "15px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "15px",
        marginBottom: "5px",
    },
    "& .selectContainer": {
        display: "flex",
        gap: "20px",
        flexWrap: "wrap"
    },
    "& .navigateRoute": {
        color: "black",
        fontSize: "16px",
        fontWeight: 400,
    },
    "& .searchIcon": {
        color: "#808080"
    },
    "& .textOrange": {
        fontWeight: 700,
        color: "orange",
    },
    "& .boldText": {
        fontWeight: 700
    },
    "& .listContainer": {
        borderRadius: "10px"
    },
    "& .realEstateHeading": {
        fontSize: "28px",
        fontWeight: 700,
        marginBottom: "15px",
        marginTop: "15px",
        lineHeight: "27px",
    },
    "& .listPara": {
        lineHeight: "27px",
        fontSize: "22px",
        fontWeight: 700,
    },
    "& .tableHeader": {
        color: "black",
        fontSize: "18px",
        fontWeight: 700,
    },
    "& .successMsg": {
        fontWeight: 700,
        backgroundColor: "#E4F8EB",
        color: "#40CE74",
        textTransform: "capitalize",
        borderRadius: "20px",
    },
    "& .errorMsg": {
        color: "#FC924B",
        backgroundColor: "#FEF0E6",
        fontWeight: 700,
        borderRadius: "20px",
        textTransform: "capitalize",
    },
});

const FilterData = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    margin: "20px",
    "& .MuiPaginationItem-page.Mui-selected": {
        background: "#fc8e45",
        color: "#fff",
    },
});

const TeamManagingWrapper = styled(Box)({
    backgroundColor: "#F4F7FF",
    padding: "15px 25px",
    "& .searchButton": {
        borderRadius: "10px",
        textTransform: "capitalize",
        fontWeight: 700,
        fontSize: "20px",
        backgroundColor: "#2B6FED"
    },
    "& .listHeader": {
        padding: "15px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "15px",
        marginBottom: "5px",
    },
    "& .navigateData": {
        fontWeight: 400,
        fontSize: "16px",
        color: "black",
    },
    "& .companiesText": {
        fontWeight: 700,
        fontSize: "28px",
        lineHeight: "27px",
        marginTop: "15px",
        marginBottom: "15px",
    },
    "& .selectContainer": {
        display: "flex",
        gap: "20px",
        flexWrap: "wrap"
    },
    "& .listContainer": {
        borderRadius: "10px"
    },
    "& .searchIcon": {
        color: "#808080"
    },
    "& .textOrange": {
        color: "orange",
        fontWeight: 700
    },
    "& .boldText": {
        fontWeight: 700
    },
    "& .listPara": {
        fontWeight: 700,
        fontSize: "22px",
        lineHeight: "27px",
    },
    "& .tableCellHeading": {
        fontWeight: 700,
        fontSize: "18px",
        color: "black",
    },
    "& .approvedMsg": {
        fontWeight: 700,
        backgroundColor: "#E4F8EB",
        color: "#40CE74",
        textTransform: "capitalize",
        borderRadius: "20px",
    },
    "& .pendingMsg": {
        fontWeight: 700,
        backgroundColor: "#FEF0E6",
        color: "#FC924B",
        textTransform: "capitalize",
        borderRadius: "20px",
    },
    "& .paginationData": {
        display: "flex",
        justifyContent: "space-between",
        margin: "20px",
    },
});
// Customizable Area End
