import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
const navigation = require('react-navigation');
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    id:string;
    navigation: typeof navigation;
    t:(value:string)=> string;
    i18n: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    companiesData: Array<any>;
    userRole: string | null;
    complex: string;
    country: string;
    city: string;
    status: string;
    searchText: string;
    countryList: Array<CountryList>;
    cityList: Array<string>;
    showError: boolean;
    showErrorMessage: string;
    showSuccess: boolean;
    showSuccessMessage: string;
    page: any;
    paginationData: any | null;
    loader: boolean;
    openReassignModal: boolean;
    modalBuildingId: number | null;
    modalBuildingName:string | null;
    modalCurrentSalesManager:string | null;
    modalCurrentAccountManager:string | null;
    accountManager: string;
    ownerDetails: any;
    salesManagerList: Array<any>;
    accountManagerList: Array<any>;
    accountManagerAssign: string;
    salesManagerAssign: string;
    noteText: string;
    employeeAccountId: number | null;

    reassignForm: {
        buildingId: any;
        buildingName: any;
        salesManager: any;
        accountManager: any;
        accountManagerId: any;
        newSalesManager: any;
        newAccountManager: any;
        note: any;
    }
    // Customizable Area End
}
// Customizable Area Start
export interface CountriesListAPI {
    data: {
        id: string;
        type: string;
        attributes:
        {
            name: string
        }
    }[]
}

interface CountryList {
    id: string;
    type: string;
    attributes:
    {
        name: string
    }
}
// Customizable Area End
interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class MyTeamController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    countryListApiCallId: string = "";
    cityListApiCallId: string = "";
    companiesListApiCallId: string = "";
    ownerDetailsApiCallId: string = "";
    accountListApiCallId: string = "";
    salesListApiCallId: string = "";
    reassignAnotherManagerApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.ContractDetailsId),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start 
            country: "",
            city: "",
            status: "",
            searchText: "",
            companiesData: [],
            userRole: "",
            complex: "",
            countryList: [],
            cityList: [],
            showError: false,
            showErrorMessage: "",
            showSuccess: false,
            showSuccessMessage: "",
            page: 1,
            paginationData: {
                current_page: null,
                next_page: null,
                prev_page: null,
                total_pages: null,
                total_count: null,
            },
            loader: false,
            openReassignModal: false,
            modalBuildingId: null,
            modalBuildingName: "",
            modalCurrentSalesManager: "",
            modalCurrentAccountManager: "",
            accountManager: "",
            noteText: "",
            ownerDetails: {
                data: {
                    reporting_person: null,
                    employee_account: null,
                    object_id: null,
                    object_name: null
                }
            },
            salesManagerList: [],
            accountManagerList: [],
            accountManagerAssign: "",
            salesManagerAssign: "",
            employeeAccountId: null,

            reassignForm: {
                buildingId: "",
                buildingName: "",
                salesManager: "",
                accountManager: "",
                accountManagerId: "",
                newSalesManager: "",
                newAccountManager: "",
                note: "",
            }
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson && !responseJson.errors) {
                this.contractDetailsApiResponseSucessCell(apiRequestCallId, responseJson);
            } else if (responseJson && responseJson.errors) {
                this.contractDetailsApiResponseFailureCall(apiRequestCallId, responseJson);
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount() {
        this.getCompanyList("1");
        this.getCountryListItems();
        this.getUserType();
        this.getAccountList();
    };

    myTeamApiCall = async (data: any) => {
        let token = localStorage.getItem("loginSuccessToken")
        let { method, endPoint, body, type = "", contentType } = data;
        const header = {
            "Content-Type": contentType,
            token: token
        };
        let myTeamrequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        myTeamrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        myTeamrequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        myTeamrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        myTeamrequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        )
        body && type !== "formData"
            ? myTeamrequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
            : myTeamrequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        runEngine.sendMessage(myTeamrequestMessage.id, myTeamrequestMessage);
        return myTeamrequestMessage.messageId;
    };

    contractDetailsApiResponseSucessCell = async (apiRequestCallId: string, responseJson: any) => {
        if (apiRequestCallId === this.countryListApiCallId) {
            this.countryListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.cityListApiCallId) {
            this.cityListItemsSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.companiesListApiCallId) {
            this.companyListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.ownerDetailsApiCallId) {
            this.ownerDetailsSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.accountListApiCallId) {
            this.accountListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.salesListApiCallId) {
            this.salesListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.reassignAnotherManagerApiCallId) {
            this.reassignToAnotherAccountManagerSucessCallBack(responseJson);
        }
    };

    contractDetailsApiResponseFailureCall = async (apiRequestCallId: string, responseJson: any) => {
        if (apiRequestCallId === this.countryListApiCallId) {
            this.countryListFailureResponseCallBack(responseJson);
        }
        if (apiRequestCallId === this.cityListApiCallId) {
            this.cityListItemsFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.companiesListApiCallId) {
            this.companyListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.ownerDetailsApiCallId) {
            this.ownerDetailsFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.accountListApiCallId) {
            this.accountListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.salesListApiCallId) {
            this.salesListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.reassignAnotherManagerApiCallId) {
            this.reassignToAnotherAccountManagerFailureCallBack(responseJson);
        }
    };

    getCountryListItems = async () => {
        this.countryListApiCallId = await this.myTeamApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: configJSON.actualCountryListEndPoint,
        });
    };

    countryListSucessCallBack = (response: CountriesListAPI) => {
        this.setState({ countryList: response.data });
    };

    countryListFailureResponseCallBack = (response: any) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
    };

    getCityListItems = async () => {
        this.cityListApiCallId = await this.myTeamApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: `bx_block_address/city_by_state?country_name=${this.state.country}`
        });
    };

    cityListItemsSucessCallBack = (response: any) => {
        this.setState({ cityList: response });
    };

    cityListItemsFailureCallBack = (response: any) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
    };

    handleAllFilter = (value: any) => value === "all" ? "" : value;

    getCompanyList = async (page: string) => {
        this.setState({loader:true});
        let endPoint = `${configJSON.companiesDataEndPoint}page=${page}`;
        if(this.state.city){
            endPoint += `&city=${this.handleAllFilter(this.state.city)}`;
        }
        if(this.state.status){
            endPoint += `&status=${this.handleAllFilter(this.state.status)}`;
        }
        if(this.state.searchText){
            endPoint += `&search=${this.state.searchText}`;
        }
        this.companiesListApiCallId = await this.myTeamApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: endPoint
        });
    };

    companyListSucessCallBack = (response: any) => {  
        this.setState({ companiesData: response.data, paginationData: response.meta.pagination, page: response.meta.pagination?.current_page, loader: false });
            };

    companyListFailureCallBack = (response: any) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0], loader: false });
    }

    getOwnerDetails = async (companyId: string | number) => {
        this.setState({ loader: true });
        this.ownerDetailsApiCallId = await this.myTeamApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: `${configJSON.ownerDetailsEndpoint}${companyId}`
        });
    };

    ownerDetailsSucessCallBack = (response: any) => {   
        this.setState({ 
            ownerDetails: response, 
            loader: false, 
            openReassignModal: true,
            modalBuildingId: response.data.object_id,
            modalBuildingName: response.data.object_name,
            modalCurrentSalesManager: response.data.reporting_person,
            modalCurrentAccountManager: response.data.employee_account
        });
    };

    ownerDetailsFailureCallBack = (response: any) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract, loader: false });
    };

    getAccountList = async () => {
        this.setState({ loader: true });
        this.accountListApiCallId = await this.myTeamApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: `bx_block_my_team/company_employee/employee_team_members/account_manager_list_for_reassign`
        });
    };

    accountListSucessCallBack = (response: any) => {
        if(response && response.data) {
            this.setState({ accountManagerList: response.data, loader: false });
        }
    };

    accountListFailureCallBack =(response: any) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract, loader: false });
    };

    getSalesList = async (endpoint: string ) => {
        this.setState({ loader: true });
        this.salesListApiCallId = await this.myTeamApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: endpoint
        });
    };

    salesListSucessCallBack = (response: any) => {
        this.setState({ salesManagerList: response.manager_liting, loader: false, openReassignModal: true });
    };

    salesListFailureCallBack =(response: any) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract, loader: false });
    };

    reassignToAnotherAccountManager = async (values: any) => {
        const body = {
            id: Number(values.buildingId),
            account_employee_team_id: values.accountManagerId,
            new_employee_account_team_id: values.newAccountManager,
            note: values.note,
        };
      
        this.setState({ loader: true }, async () => {
            this.reassignAnotherManagerApiCallId = await this.myTeamApiCall({
                contentType: "application/json",
                method: configJSON.httpPut,
                endPoint: `bx_block_real_estate_companies/reassign_company`,
                body: body,
            });
        });
    };

    reassignToAnotherAccountManagerSucessCallBack = (response: any) => {
        this.setState({ showSuccess: true, showSuccessMessage: response.message }, () => {
            this.getCompanyList("1");
        });
    };

    reassignToAnotherAccountManagerFailureCallBack = async (response: any) => {
        this.setState({ showError: true, showErrorMessage: response.errors, loader: false });
    };

    getUserType = async () => {
        const storedRole = localStorage.getItem('role');
        this.setState({ userRole: storedRole })
    };

    handleCountryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ country: event.target.value as string, cityList: [], city: "" }, () => {
            this.getCityListItems();
        });
    };

    handleCityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ city: event.target.value as string });
    };

    handleStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ status: event.target.value as string });
    };

    handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchText: event.target.value }, () => {
            this.getCompanyList("1")
        });
    };

    handleNavigation = (path: string) => {
        const msgData: Message = new Message(
            getName(MessageEnum.NavigationMessageSendData)
        );
        msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), path);
        this.send(msgData);
    };

    handleAlertError = () => {
        this.setState({ showError: false });
    };

    handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        const newPageCount = Number(value);
        this.setState({ page: newPageCount }, () => {
            this.getCompanyList(JSON.stringify(newPageCount));
        });
    };

    handleReassignModal = () => {
        this.setState({ openReassignModal: false });
    };

    handleReassign = (company: any) => {
        this.setState({
            openReassignModal: true,
            reassignForm: {
              buildingId: company.id,
              buildingName: company.attributes.company_name,
              salesManager: company.attributes.account_sales_manager?.sales_manager ?
              company.attributes.account_sales_manager?.sales_manager?.first_name +
                " " +
                company.attributes.account_sales_manager?.sales_manager?.last_name: "",
              accountManager: company.attributes.account_sales_manager?.account_manager ?
              company.attributes.account_sales_manager?.account_manager?.first_name +
                " " +
                company.attributes.account_sales_manager?.account_manager?.last_name : "",
              accountManagerId: company.attributes.account_sales_manager?.account_manager?.id,
              newSalesManager: "",
              newAccountManager: "",
              note: "",
            },
          });
    };

    handleSuccessAlert = () => {
        this.setState({ showSuccess: false });
    };

    handleClearFiltersClick = async () => {
        this.setState({country:"", city:"", status:""}, () => {
            this.getCompanyList("1");
        })
    }
    // Customizable Area End
}
